import Vue from "vue";
// import App from "./App.vue";
import axios from "axios";
import bd from "./assets/bd";
import cookie from "./assets/cookie";
import Toasted from "vue-toasted";
import VueProgressBar from "vue-progressbar";
import Paginate from "vuejs-paginate";

Vue.config.productionTip = false;
Vue.prototype.$bd = bd.utils;
Vue.prototype.$cookie = cookie;

Vue.prototype.$axios = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.NODE_ENV === "production" ? "/sp/morest/ws/21000/api" : "/sp/morest/ws/6990/api"
});

// https://github.com/shakee93/vue-toasted
Vue.use(Toasted, {
  theme: "outline",
  type: "info",
  position: "bottom-center",
  duration: 5000,
  keepOnHover: true,
  action: {
    text: "X",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    }
  }
});

// http://hilongjw.github.io/vue-progressbar/index.html
Vue.use(VueProgressBar, {
  color: "green",
  failedColor: "red",
  thickness: "8px"
});

// https://github.com/lokyoung/vuejs-paginate
Vue.component("paginate", Paginate);

export { Vue };
