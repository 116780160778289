//---------------------omnis logger--------------------
import axios from "axios";

window.LASTERRORSTAMP = 0;

window.thinlogger = function(message, caller) {
  message = typeof message !== "undefined" ? message : "";
  caller = typeof caller !== "undefined" ? caller : document.URL;

  // eslint-disable-next-line no-undef
  if (process.NODE_ENV !== "production") {
    window.console.log(message + ". Caller: " + caller);
  }
  const logger =
    // eslint-disable-next-line no-undef
    process.env.NODE_ENV === "production"
      ? "/sp/morest/ws/21000/api/BD/utils/logger"
      : "/sp/morest/ws/6990/api/BD/utils/logger";

  //to avoid flooding the server, do not report more that 1 error each half second
  if (Date.now() - window.LASTERRORSTAMP > 500) {
    axios.get(logger, {
      params: {
        message: btoa(message),
        caller: btoa(caller)
      }
    });
  }

  window.LASTERRORSTAMP = Date.now();
};

// global event listener for any javascript errors
window.addEventListener("error", function(err) {
  window.thinlogger(err.message + ". Url: " + err.filename + "." + err.lineno + "." + err.colno);
});
//---------------------fin omnis logger--------------------

let utils = {
  isTrue: true, // utiles pour mettre
  isFalse: false, // des valeurs typées
  isZero: 0, // dans les attributs quasar
  isOne: 1,
  isNumeric: function(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
};

utils.escapeHtml = function(unsafe) {
  if (!unsafe || unsafe == "undefined") {
    return "";
  }
  return unsafe
    .replace(/&/g, " ")
    .replace(/</g, " ")
    .replace(/>/g, " ")
    .replace(/"/g, " ")
    .replace(/\\/g, " ")
    .replace(/\//g, " ");
};

utils.getCookie = function(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2)
    return parts
      .pop()
      .split(";")
      .shift();
};

// recupere les parametres de l'url courante et renvoie un objet
utils.getUrlParams = function(params) {
  params = typeof params !== "undefined" ? params : {};
  if (!window.location.href.includes("?")) {
    return params;
  }

  let query = window.location.href.slice(window.location.href.indexOf("?") + 1);
  // get rid of # that interfere
  query = query.replace("#", "&");

  const args = query.split("&");
  args.forEach(arg => {
    const a = arg.split("=");
    if (a[1]) {
      if (utils.isNumeric(a[1])) {
        params[a[0]] = a[1] * 1; // type cast to int
      } else {
        params[a[0]] = decodeURIComponent(a[1]);
      }
    }
  });

  return params;
};

utils.getValueOfUrlParam = function(paramname, url) {
  if (!url) url = window.location.href;
  url = url.slice(url.indexOf("?") + 1);
  const params = url.split("&");
  for (let i = 0; i < params.length; i++) {
    const param = params[i].split("=");
    if (param[0] == paramname) {
      return decodeURIComponent(param[1]);
    }
  }
};

export default {
  utils
};
