<script>
export default {
  name: "App",
  data() {
    return {
      // eslint-disable-next-line no-undef
      ServerName: process.env.NODE_ENV === "production" ? "" : "https://www.spadaccini.fr",
      FetchLimit: 30, // constante
      page: 1,
      pagecount: 0,
      rowcount: 0,
      Favoris: [],
      Noms: [],
      Selections: [],
      Tris: [],
      Datas: [],
      FavDatas: []
    };
  },
  computed: {
    Params() {
      return this.$bd.getUrlParams({
        lang: window.location.hostname.includes(".com") ? "en" : "fr",
        selection: "tranches",
        tri: "date",
        filtre: "",
        qte: ""
      });
    }
  },
  created() {
    this.getFavoris();
    this.$Progress.start();
    this.getCombos();
    this.getDatas(0);
  },
  methods: {
    onChangeCombo(e) {
      // changement de sélection ou de tri
      if (e.target.value === "-") return false;
      this.submit();
    },
    setQuery() {
      let query = "";
      for (const key in this.Params) {
        if (this.Params[key]) {
          query = query + "&" + key + "=" + this.Params[key];
        }
      }
      return query.substring(1);
    },
    submit() {
      window.location = window.location.pathname + "?" + this.setQuery();
    },
    getPage() {
      // changement de page
      this.$Progress.start();
      this.getDatas(this.page);
    },
    getDatas(page) {
      let url = "/icat/api/liste?" + this.setQuery();
      // changement de page
      if (page) url = url + "&page=" + page;

      this.$axios
        .get(url)
        .then(response => {
          if (response.data.messagetype !== "success") {
            this.$toasted.info(response.data.message);
          }
          this.Datas = response.data.Datas;
          // init pagination
          if (!page) {
            this.rowcount = response.data.rowcount;
            this.FetchLimit = response.data.FetchLimit;
            this.pagecount = Math.trunc(this.rowcount / this.FetchLimit) + (this.rowcount % this.FetchLimit > 0) * 1;
          }
        })

        .catch(e => {
          this.$toasted.info("Erreur réseau. Veuillez recommencer ultérieurement");
          window.thinlogger("axios " + e + " response " + JSON.stringify(e.response));
        })

        .finally(() => {
          this.$Progress.finish();
        });
    },
    getCombos() {
      // get noms datalist
      window
        .fetch("/icat/json/noms-varietes.json")
        .then(response => {
          return response.json();
        })
        .then(json => {
          this.Noms = json;
        });

      // get selections and tris
      this.$axios.get("/icat/api/liste/combos?lang=" + this.Params.lang).then(response => {
        if (response.data.messagetype === "success") {
          this.Selections = response.data.Selections;
          this.Tris = response.data.Tris;
        }
      });
    },
    setDateMaj(lodatar) {
      // nettoyage des dates trop anciennes
      const ThisYear = new Date().getFullYear();
      let annee = lodatar.substring(6);
      if (annee < ThisYear - 1) {
        annee = ThisYear - 1;
      }
      return annee + "-" + lodatar.substring(3, 5);
    },
    getFavoris() {
      let s = this.$cookie.getItem("Favoris");
      if (!s || s == "undefined") return;
      this.Favoris = s.split(",");
      this.$axios.get("/icat/api/favoris?lorefs=" + s).then(response => {
        if (response.data.messagetype == "success") {
          this.FavDatas = response.data.Datas;
        }
      });
    },
    favoriSrc(loref) {
      if (this.Favoris.includes(loref)) {
        return "img/sprite.svg#favorite--active";
      } else {
        return "img/sprite.svg#favorite";
      }
    },
    toggleFavori(loref) {
      // animate it voir https://daneden.github.io/animate.css/
      let animation = "bounce";
      const ref = this.$refs[loref][0];

      const favindex = this.Favoris.findIndex(item => item === loref);
      if (favindex >= 0) {
        this.Favoris.splice(favindex, 1); // remove it
        animation = "rotateIn";
        window.location.hash = "_";
      } else {
        this.Favoris.push(loref); // add it
        animation = "rotateOut";
        window.location.hash = "ajout-lot-favori-" + loref;
      }
      ref.classList.add(animation);
      this.$cookie.setItem("Favoris", this.Favoris.join(","), Infinity);

      setTimeout(() => {
        // reset animation state
        ref.classList.remove(animation);
        // will recreate FavDatas then rebuild the DOM for the favoris section
        // must wait for the animation to complete to be user friendly
        if (!ref.classList.contains("in-block-favoris")) {
          this.getFavoris();
        }
      }, 1000);
    },
    toggleAria(e) {
      const button = e.target;
      const wrapper = button.parentNode.nextElementSibling;

      //"aria-expanded" == "true" is a string instead of boolean because
      // css testes it as a bloody string .c-result__button[aria-expanded="false"]

      if (button.getAttribute("aria-expanded") == "true") {
        button.setAttribute("aria-expanded", "false"); //keep it as string
        wrapper.setAttribute("aria-hidden", true);
      } else {
        button.setAttribute("aria-expanded", "true"); //keep it as string
        wrapper.setAttribute("aria-hidden", false);
      }
    }
  }
};
</script>

<template>
  <div id="app">
    <main class="c-main" role="main">
      <div class="o-wrapper">
        <nav class="c-breadcrumb u-hidden--um" aria-label="Vous êtes ici">
          <ul class="c-breadcrumb__list">
            <li class="c-breadcrumb__item">
              <a href="/">{{ Params.lang === "fr" ? "Accueil" : "Home" }}</a>
            </li>
            <li class="c-breadcrumb__item">Stock</li>
          </ul>
        </nav>

        <h1 class="c-h1">Stock</h1>

        <form class="c-form c-form--dark" @submit.prevent="submit">
          <div class="o-flex">
            <div class="o-flex__half c-form__container">
              <label class="c-form__label" for="select-choice">
                {{ Params.lang === "fr" ? "Ma sélection" : "My choice" }}
              </label>
              <div class="c-form__select">
                <select id="select-choice" v-model="Params.selection" @change="onChangeCombo">
                  <option v-for="(selection, index) in Selections" :key="index" :value="selection.code">{{
                    selection.libelle
                  }}</option>
                </select>
              </div>
            </div>

            <div class="o-flex__half c-form__container">
              <label class="c-form__label" for="input-filter">{{
                Params.lang === "fr" ? "Filtrer les produits par" : "Filter products by"
              }}</label>
              <input
                id="input-filter"
                v-model="Params.filtre"
                class="c-form__input"
                type="text"
                :placeholder="Params.lang === 'fr' ? 'Nom, matière, couleur…' : 'Name, material, color…'"
                list="input-list"
                @change="submit"
              />
              <datalist id="input-list">
                <option v-for="(nom, index) in Noms" :key="index" :value="nom" />
              </datalist>
            </div>

            <div class="o-flex__half c-form__container">
              <label class="c-form__label" for="input-qty">
                {{ Params.lang === "fr" ? "Quantité souhaitée" : "Quantity needed" }}
              </label>
              <input
                id="input-qty"
                v-model="Params.qte"
                class="c-form__input"
                type="number"
                :placeholder="1"
                @change="submit"
              />
            </div>

            <div class="o-flex__half c-form__container">
              <label class="c-form__label" for="select-sort">
                {{ Params.lang === "fr" ? "Trier les résultats par" : "Sort results by" }}
              </label>
              <div class="c-form__select">
                <select id="select-sort" v-model="Params.tri" @change="onChangeCombo">
                  <option v-for="tri in Tris" :key="tri.libelle" :value="tri.libelle">{{
                    Params.lang === "fr" ? tri.libelle : tri.libelle_uk
                  }}</option>
                </select>
              </div>
            </div>
          </div>
        </form>

        <!-- début favoris-->
        <h2 class="c-h2">{{ Params.lang === "fr" ? "Mes favoris" : "My bookmarks" }}</h2>

        <!-- fiche favori -->
        <div
          v-for="lot in FavDatas"
          v-show="Favoris.length > 0"
          :key="lot.loref"
          class="c-result c-result--favorite / js-dropdown"
        >
          <!-- titre produit favori -->
          <h3 class="c-h4 c-result__title">
            <button
              type="button"
              class="c-result__button / js-dropdown__button"
              aria-expanded="true"
              @click="toggleAria"
            >
              {{
                Params.lang === "fr"
                  ? lot.texte.substring(0, lot.texte.indexOf("\r"))
                  : lot.texte_en.substring(0, lot.texte_en.indexOf("\r"))
              }}
            </button>
          </h3>

          <!-- wrapper lot favori-->
          <div class="c-result__wrapper / js-dropdown__wrapper" aria-hidden="false">
            <!-- fiche lot favori-->
            <div class="c-result__container">
              <!-- entete lot favori-->
              <div class="lot-title">
                <a :href="'fiche-produit.html?cle=' + lot.cle">
                  <h4 class="c-result__subtitle">{{ Params.lang === "fr" ? lot.lo_texte : lot.lo_texte_en }}</h4>
                </a>
                <h4 v-if="lot.lo_prix > 0" class="c-h5">
                  {{ lot.lo_prix }}&nbsp;&euro;&nbsp;HT&nbsp;{{lot.matiere === "neolith" ? "net" : "brut"}}&nbsp;/&nbsp;{{ lot.lo_unite }}
                </h4>
                <a
                  class="c-favorite"
                  :title="Params.lang === 'fr' ? 'Favori' : 'Bookmark'"
                  @click="toggleFavori(lot.loref)"
                >
                  <img
                    :alt="lot.loref"
                    :ref="lot.loref"
                    class="lot-title-img in-block-favoris animated"
                    :src="favoriSrc(lot.loref)"
                  />
                </a>
              </div>
              <!-- fin entete lot favori -->

              <!-- image favori-->
              <a class="c-result__link" tabindex="-1" :href="'#' + lot.loref">
                <img
                  loading="eager"
                  class="c-result__img"
                  width="300"
                  :src="ServerName + lot.lo_url"
                  :alt="Params.lang === 'fr' ? lot.lo_texte : lot.lo_texte_en"
                />
              </a>

              <!-- favori image hd overlay -->
              <a :id="lot.loref" tabindex="-1" href="#_" class="c-overlay">
                <div class="overlay-flex">
                  <img
                    class="c-overlay__img"
                    loading="lazy"
                    :src="ServerName + lot.lo_url"
                    :alt="Params.lang === 'fr' ? lot.lo_texte : lot.lo_texte_en"
                  />
                  <div v-if="!lot.lo_url.includes(lot.loref)" class="overlay-text">
                    {{
                      Params.lang === "fr"
                        ? "Image de substitution, la photo du lot n'est pas disponible sur le site"
                        : "Replacement image, the product's photo is not available on the site"
                    }}
                  </div>
                </div>
              </a>
              <!-- fin image hd overlay-->
              <!-- fin image -->

              <!-- dimensions lot favori-->
              <table>
                <tr>
                  <th>
                    Dimensions
                    <span class="u-hidden--um">{{ Params.lang === "fr" ? "en " : "in " }}cm</span>
                    <span class="u-hidden--fm">(cm)</span>
                  </th>
                  <th>
                    <span class="u-hidden--um">{{ Params.lang === "fr" ? "Etat du " : "Current " }}</span
                    >stock
                  </th>
                </tr>
                <tr>
                  <td>{{ Params.lang === "fr" ? "Longueur" : "length" }} : {{ lot.lo_long }}</td>
                  <td>
                    <span class="u-hidden--um">{{ Params.lang === "fr" ? "Quantité " : "Quantity " }}</span>
                    {{ Params.lang === "fr" ? "disponible" : "available" }} : {{ lot.lo_dispo }} -
                    {{ lot.lo_dispo_m2 }}
                    <abbr>
                      m
                      <sup>2</sup>
                    </abbr>
                  </td>
                </tr>

                <tr>
                  <td>{{ Params.lang === "fr" ? "Largeur" : "width" }} : {{ lot.lo_larg }}</td>
                  <td>
                    <span class="u-hidden--um">{{ Params.lang === "fr" ? "Quantité " : "Quantity " }}</span>
                    {{ Params.lang === "fr" ? "réservé" : "reserved" }} : {{ lot.lo_resa }} -
                    {{ lot.lo_resa_m2 }}
                    <abbr>
                      m
                      <sup>2</sup>
                    </abbr>
                  </td>
                </tr>

                <tr>
                  <td>
                    {{ Params.lang === "fr" ? "Épaisseur" : "Thickness" }} :
                    {{ lot.lo_epaisseur.replace("cm", "") }}
                  </td>
                  <td>
                    <span v-if="!lot.lo_url.includes(lot.loref)">
                      {{ Params.lang === "fr" ? "Image de substitution" : "Replacement image" }}
                    </span>
                  </td>
                </tr>
              </table>

              <!-- fin dimensions lot favori -->
            </div>
            <!-- fin fiche lot favori-->
          </div>
          <!-- fin wrapper produit favori -->
        </div>
        <!-- fin fiche favori -->
        <!-- fin favoris -->

        <!-- debut resultats -->
        <h2 class="c-h2">{{ rowcount }} {{ Params.lang === "fr" ? " résultats" : " results" }}</h2>

        <!-- fiche produit -->
        <div v-for="prdt in Datas" :key="prdt.cle" class="c-result / js-dropdown">
          <!-- entete produit -->
          <h3 class="c-h4 c-result__title">
            <button
              type="button"
              class="c-result__button / js-dropdown__button"
              aria-expanded="true"
              @click="toggleAria"
            >
              {{
                Params.lang === "fr"
                  ? prdt.texte.substring(0, prdt.texte.indexOf("\r"))
                  : prdt.texte_en.substring(0, prdt.texte_en.indexOf("\r"))
              }}
            </button>
          </h3>

          <!-- wrapper produit -->
          <div class="c-result__wrapper / js-dropdown__wrapper" aria-hidden="false">
            <!-- fiche lot -->
            <div v-for="lot in prdt.Lots" :key="lot.loref" class="c-result__container">
              <!-- entete lot -->
              <div class="lot-title">
                <div>
                  <a :href="'fiche-produit.html?cle=' + lot.cle">
                    <h4 class="c-result__subtitle">{{ Params.lang === "fr" ? lot.lo_texte : lot.lo_texte_en }}</h4>
                  </a>
                </div>
                <h4 v-if="lot.lo_prix > 0" class="c-h5">
                  {{ lot.lo_prix }}&nbsp;&euro;&nbsp;HT&nbsp;{{lot.matiere === "neolith" ? "net" : "brut"}}&nbsp;/&nbsp;{{ lot.lo_unite }}
                </h4>
                <div>
                  <a
                    class="c-favorite"
                    :title="Params.lang === 'fr' ? 'Favori' : 'Bookmark'"
                    @click="toggleFavori(lot.loref)"
                  >
                    <!-- :id="'favori-' + lot.loref" -->
                    <img :alt="lot.loref" :ref="lot.loref" class="lot-title-img animated" :src="favoriSrc(lot.loref)" />
                  </a>
                </div>
              </div>
              <!-- fin entete lot -->

              <!-- image lot-->

              <a class="c-result__link" tabindex="-1" :href="'#' + lot.loref">
                <div class="figure-flex">
                  <img
                    loading="eager"
                    class="c-result__img"
                    width="300"
                    :src="ServerName + lot.lo_url.replace('.jpg', '-vignette.jpg')"
                    :alt="Params.lang === 'fr' ? lot.lo_texte : lot.lo_texte_en"
                  />
                </div>
              </a>

              <!-- image lot hd overlay -->
              <a :id="lot.loref" tabindex="-1" href="#_" class="c-overlay">
                <div class="overlay-flex">
                  <img
                    class="c-overlay__img"
                    loading="lazy"
                    :src="ServerName + lot.lo_url"
                    :alt="Params.lang === 'fr' ? lot.lo_texte : lot.lo_texte_en"
                  />
                  <div v-if="!lot.lo_url.includes(lot.loref)" class="overlay-text">
                    {{
                      Params.lang === "fr"
                        ? "Image de substitution, la photo du lot n'est pas disponible sur le site"
                        : "Replacement image, the product's photo is not available on the site"
                    }}
                  </div>
                </div>
              </a>

              <!-- Ancienne version 2020-07-19 <a :id="lot.loref" tabindex="-1" href="#_" class="c-overlay">
                <img
                  class="c-overlay__img"
                  loading="lazy"
                  :src="ServerName + lot.lo_url"
                  :alt="Params.lang === 'fr' ? lot.lo_texte : lot.lo_texte_en"
                />
              </a>-->
              <!-- fin image lot hd overlay-->
              <!-- fin image lot-->

              <!-- dimensions lot -->
              <table>
                <tr>
                  <th>
                    Dimensions
                    <span class="u-hidden--um">{{ Params.lang === "fr" ? "en " : "in " }}cm</span>
                    <span class="u-hidden--fm">(cm)</span>
                  </th>
                  <th>
                    <span class="u-hidden--um">{{ Params.lang === "fr" ? "Etat du " : "Current " }}</span>
                    <span>stock</span>
                  </th>
                </tr>
                <tr>
                  <td>{{ Params.lang === "fr" ? "Longueur" : "length" }} : {{ lot.lo_long }}</td>
                  <td>
                    <span class="u-hidden--um">{{ Params.lang === "fr" ? "Quantité " : "Quantity " }}</span>
                    {{ Params.lang === "fr" ? "disponible" : "available" }} : {{ lot.lo_dispo }} -
                    {{ lot.lo_dispo_m2 }}
                    <abbr>
                      m
                      <sup>2</sup>
                    </abbr>
                  </td>
                </tr>

                <tr>
                  <td>{{ Params.lang === "fr" ? "Largeur" : "width" }} : {{ lot.lo_larg }}</td>
                  <td>
                    <span class="u-hidden--um">{{ Params.lang === "fr" ? "Quantité " : "Quantity " }}</span>
                    {{ Params.lang === "fr" ? "réservé" : "reserved" }} : {{ lot.lo_resa }} -
                    {{ lot.lo_resa_m2 }}
                    <abbr>
                      m
                      <sup>2</sup>
                    </abbr>
                  </td>
                </tr>

                <tr>
                  <td>
                    {{ Params.lang === "fr" ? "Épaisseur" : "Thickness" }} :
                    {{ lot.lo_epaisseur.replace("cm", "") }}
                  </td>
                  <td>
                    <!-- Ne plus faire BDS 2020-07-19 <span
                      class="u-hidden--um"
                    >{{ Params.lang === "fr" ? "Mise à jour : " : "Date : " }}</span>
                    <span>{{ setDateMaj( lot.lo_arrivee ) }}</span>-->

                    <span v-if="!lot.lo_url.includes(lot.loref)">
                      {{ Params.lang === "fr" ? "Image de substitution" : "Replacement image" }}
                    </span>
                  </td>
                </tr>
              </table>

              <!-- fin dimensions lot -->
            </div>
            <!-- fin fiche lot -->
          </div>
          <!-- fin wrapper produit -->
        </div>
        <!-- fin resultat -->

        <!-- pagination -->
        <div v-if="pagecount > 1">
          <paginate
            v-model="page"
            :page-count="pagecount"
            :click-handler="getPage"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'c-pagination'"
            :prev-class="'c-pagination__item'"
            :next-class="'c-pagination__item'"
            :page-class="'c-pagination__item'"
            :prev-link-class="'c-pagination__link'"
            :next-link-class="'c-pagination__link'"
            :page-link-class="'c-pagination__link'"
          ></paginate>
        </div>
        <!-- fin pagination -->
      </div>
      <!-- fin wrapper -->
    </main>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<style>
.overlay-flex {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.overlay-text,
.overlay-text:hover {
  color: lightgray;
  font-weight: 200;
  font-size: 1.1em;
}
.c-overlay:hover {
  text-decoration: none;
  outline: unset;
}
.lot-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.lot-title-img {
  width: 22px;
  height: 22px;
}
.c-result__action {
  position: inherit;
}
.c-result__subtitle {
  border-top: none;
}
.c-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1.4em;
}
.c-pagination__item {
  border: 1px solid grey;
  border-radius: 2px;
  margin-left: 8px;
  margin-bottom: 8px;
}
.c-pagination__item.active {
  background-color: #134393;
}
.c-pagination__item.disabled {
  border: 1px solid lightgrey;
}
.c-pagination__item.disabled:hover {
  background-color: white;
  cursor: not-allowed;
}
.c-pagination__item:hover {
  background-color: #134393;
}
.c-pagination__link {
  margin: 0;
  width: 36px;
  height: 36px;
  line-height: 2;
}
.c-pagination__link:hover {
  color: white;
}
</style>
